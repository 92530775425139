<template>
  <v-container fluid grid-list-lg>
    <Create :createDialog="createDialog" @close="createDialog = false" @submit="getList"></Create>
    <Edit :editDialog="editDialog" @close="editDialog = false" @submit="getList" :editExpense="selected"></Edit>
    <v-card :loading="loading" outlined>
      <ListTitle :showBackButton="true" :access="checkRightStatus(4)" title="Expense" @add="add" :color="$style.purchase.titlebar"/>

      <!-- <v-card-text class="mb-0 pb-0">
        <v-row>
          <v-col cols="12" md="3" sm="12">
            <v-text-field v-model="search" outlined prepend-inner-icon="search" rounded dense label="Search" single-line
              hide-details clearable>
            </v-text-field>
          </v-col>
          <v-spacer> </v-spacer>
          <v-col cols="12" md="3" sm="12">
            <DateTimePicker defaultRange="thisMonth" :range="true" :hideTime="true" v-model="dateTime"
              @submit="getList()" />
          </v-col>
        </v-row>
      </v-card-text> -->

      <div v-if="$vuetify.breakpoint.mdAndUp">
        <v-toolbar dense elevation="0">

          <v-text-field v-model="search" outlined rounded prepend-inner-icon="search" autofocus dense label="Search" single-line
            hide-details clearable></v-text-field>

          <!-- <v-overflow-btn :items="dropdown_font" label="Select font" hide-details class="pa-0"></v-overflow-btn> -->


          
          



          <v-spacer></v-spacer>




          <v-spacer></v-spacer>
          <DateTimePicker
            :defaultRange="currentBusiness.metadata.invoiceViewLimit ? currentBusiness.metadata.invoiceViewLimit.range : 'since15DaysAgo'"
            :range="true" :hideTime="true" v-model="dateTime" @submit="getList()" />
        </v-toolbar>
        <v-divider></v-divider>
      </div>

      <v-card-text class="mb-0 pb-0" v-else>
        <v-row>
          <v-col cols="12" md="3" sm="12">
            <v-text-field v-model="search" outlined prepend-inner-icon="search" rounded dense label="Search" single-line
              hide-details clearable>
            </v-text-field>
          </v-col>
          <v-spacer> </v-spacer>
          <v-col cols="12" md="4" sm="12" v-if="!showAll">
            <DateTimePicker
              :defaultRange="currentBusiness.metadata.invoiceViewLimit ? currentBusiness.metadata.invoiceViewLimit.range : 'since60DaysAgo'"
              :range="true" :hideTime="true" v-model="dateTime" @submit="getList()" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-data-table :headers="headers" :items="expenses" :sortDesc="true" :search="search" :loading="loading"
          :sort-by="sortBy" @current-items="updateTotal" :page.sync="page" :items-per-page="itemsPerPage">
          <template v-slot:item="{ item }">
            <tr class="pointer">
              <td class="text-left">
                {{ item.date | moment("MM/DD/YYYY") }}
              </td>
              <td class="text-left text-capitalize ">
                {{ item.Payee.name }}
              </td>

              <td class="text-left">
                {{ item.note }}
              </td>
              <td class="text-right">{{ item.amount | currency }}</td>
              <td class="text-left text-capitalize ">
                {{ item.User.name }}
              </td>

              <td class="justify-end align-center text-right">
                <v-menu bottom left offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon>
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="menu-button">
                    <v-list-item v-if="checkRightStatus(20)" @click="editExpense(item)">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="checkRightStatus(12)" @click="deleteExpense(item)">
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no
            results.</v-alert>
          <template v-slot:body.append v-if="expenses.length > 0">
            <tr :style="{ 'background-color': $style.listTotal.filter }">
              <td :colspan="3" class="text-right font-weight-bold">
                List Total
              </td>

              <td class="text-right font-weight-bold">
                {{ total | currency }}
              </td>
              <td></td>
            </tr>
            <tr :style="{ 'background-color': $style.listTotal.result }">
              <td :colspan="3" class="text-right font-weight-bold">
                Result Total
              </td>

              <td class="text-right font-weight-bold">
                {{ fullTotal | currency }}
              </td>
              <td colspan="2"></td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import expenseService from "../service";
import DateTimePicker from "../../../components/DateTimePicker";
import { mapGetters } from "vuex";

const Create = () => import("./Create");
const Edit = () => import("./Edit");
export default {
  name: "expense-list",
  data() {
    return {
      dateTime: {
        start: this.$moment()
          .startOf("day")
          .toISOString(),
        end: this.$moment()
          .endOf("day")
          .toISOString(),
      },
      createDialog: false,
      editDialog: false,
      selected: null,
      total: 0,
      fullTotal: 0,
      headers: [
        {
          text: this.$t("labels.createdAt"),
          align: "left",
          value: "date",
          sortable: true,
        },
        {
          text: "Payee",
          align: "left",
          value: "Payee.name",
          sortable: true,
        },

        {
          text: "Note",
          value: "note",
          sortable: true,
        },
        {
          text: "Amount",
          value: "amount",
          align: "center",
          sortable: true,
        },
        {
          text: "Created By",
          value: "User.name",
          sortable: true,
        },
        {
          text: "",
          value: "action",
          sortable: false,
        },
      ],
      expenses: [],
      search: null,
      loading: true,
      page: 1,
      sortBy: "createdAt",
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  created() {
    // this.getList();
    this.$events.listen("expenseListEvent", () => this.getList());
  },
  beforeDestroy() {
    this.$events.remove("expenseListEvent");
  },
  components: {
    Create,
    DateTimePicker,
    Edit,
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus", "currentBusiness"]),
  },
  methods: {
    updateTotal(val) {
      this.total = 0;
      val.map((row) => {
        this.total += row.amount;
      });
    },
    add() {
      this.createDialog = !this.createDialog;
    },
    getList() {
      this.loading = true;
      (this.editDialog = false), (this.createDialog = false);
      return expenseService
        .getAll({
          start: this.dateTime.start,
          end: this.dateTime.end,
        })
        .then((response) => {
          this.expenses = response.data;
          this.fullTotal = 0;
          this.expenses.map((row) => {
            this.fullTotal += row.amount;
          });
          this.loading = false;
          return response;
        });
    },
    editExpense(expense) {
      this.selected = expense;
      this.editDialog = !this.editDialog;
    },
    deleteExpense(expense) {
      if (this.currentBusiness.metadata && this.currentBusiness.metadata.deletePrecaution) {
        this.$swal.fire({
          title: "Are you sure?",
          html: `You won't be able to revert this.</br> <div style="color:red;">Type <span style="font-weight: bold">delete</span> in order remove this</div>`,
          icon: "question",
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Delete",
        }).then((result) => {
          if (result.value && result.value === 'delete') {
            expenseService.delete(expense.id).then((response) => {
              if (response.data) {
                this.$swal("Deleted!", "Expense has been deleted.", "success");
                this.getList();
              }
            });
          } else if (result.dismiss === 'cancel') {

          } else {
            this.$swal("Delete", 'Type "delete" to delete item', "error");
          }
        })
      } else {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            expenseService.delete(expense.id).then((response) => {
              if (response.data) {
                this.$swal("Deleted!", "Expense has been deleted.", "success");
                this.getList();
              }
            });
          }
        });
      }
    },
  },
};
</script>
