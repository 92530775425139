<template>
  <div>
    <Create :createDialog="createDialog" @close="createDialog = false">
    </Create>
    <v-toolbar dense flat>
      <v-app-bar-nav-icon small to="/expense/home">
        <v-icon>home</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Expense</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn
        color="teal"
        text
        :fab="$vuetify.breakpoint.xsOnly"
        v-if="checkRightStatus(4)"
        dark
        @click="add"
      >
        <span v-if="!$vuetify.breakpoint.xsOnly">New</span>
        <v-icon v-else>add</v-icon>
      </v-btn>
      <v-btn
        color="teal"
        :fab="$vuetify.breakpoint.xsOnly"
        text
        dark
        to="/expense/list"
      >
        <span v-if="!$vuetify.breakpoint.xsOnly">List</span>
        <v-icon v-else>list</v-icon>
      </v-btn>
      <v-btn text color="teal" dark to="/expense/payee/list">Payee</v-btn>
    </v-toolbar>
    <v-divider></v-divider>

    <router-view> </router-view>
  </div>
</template>
<script>
import RegisterStoreModule from "@/mixins/RegisterStoreModule";
import expenseStore from "./store.js";
import { mapGetters } from "vuex";

const Create = () => import("../Expense/components/Create");

export default {
  name: "expense",
  mixins: [RegisterStoreModule],
  created() {
    this.registerStoreModule("expense", expenseStore);
    document.title = "smplsale - Expense List";
  },
  data() {
    return {
      active: null,
      createDialog: false
    };
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"])
  },
  methods: {
    createdLead() {
      this.close();
      this.getList();
    },
    add() {
      this.createDialog = true;
    },
    close() {
      this.createDialog = false;
    }
  },
  components: {
    Create
  }
};
</script>
