<template>
  <div class="Payee-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card flat>
        <ReportViewBar v-if="Payee" :title="`Payee List`" />

        <v-container fluid>
          <table class="center">
            <tbody class="report-container">
              <v-card-text>
                <v-data-table
                  dense
                  readonly
                  :headers="headers"
                  :itemsPerPage="Payee.length"
                  hide-default-footer
                  :items="Payee"
                >
                </v-data-table>
              </v-card-text>
            </tbody>
          </table>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import ReportViewBar from "@/components/ReportViewBar";
// import CategoryService from "@/modules/Category/service";
import payeeService from "@/modules/Expense/Payee/service";
// import Hashids from "hashids";
// const hashids = new Hashids();

export default {
  data() {
    return {
      start: null,
      end: null,
      categoryName: null,
      isLoading: true,
      Payee: [],
      headers: [
        {
          text: "NAME",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "ADDRESS",
          align: "center",
          value: "address",
          sortable: true,
        },
        {
          text: "CITY",
          align: "center",
          value: "city",
          sortable: true,
        },
        {
          text: "STATE",
          align: "center",
          value: "state",
          sortable: true,
        },
        {
          text: "ZIP",
          align: "center",
          value: "zip",
          sortable: true,
        },
        {
          text: "PHONE NUMBER",
          align: "center",
          value: "phoneNumber",
          sortable: true,
        },
      ],
    };
  },
  async created() {
    await this.getPayee();
  },
  components: { ReportViewBar },
  methods: {
    getPayee() {
      return payeeService.getAll().then((response) => {
        // console.log("response category", response);
        this.$Progress.finish();
        this.Payee = response.data;
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
  .no-print {
    display: none;
  }
  .detailBox {
    overflow: visible;
    height: 100%;
  }
  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}
.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}
.center {
  background-color: white;
  margin: auto;
  width: 100%;
}
/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
