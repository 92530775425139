<template>
  <div class="Product-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card flat>
        <ReportViewBar
          v-if="Expense"
          :title="`Expense By Payee`"
          :start="start"
          :end="end"
        />

        <v-container>
          <table class="center">
            <tbody class="report-container">
              <v-card-text>
                <v-data-table
                  dense
                  readonly
                  sort-by="name"
                  :headers="headers"
                  :itemsPerPage="Expense.length"
                  hide-default-footer
                  :expanded.sync="expanded"
                  show-expand
                  :items="Expense"
                >
                  <template v-slot:item="{ item, isExpanded, expand }">
                    <tr v-if="item.totalExpenses">
                      <td class="text-left">
                        <v-btn
                          class="no-print"
                          @click="expand(true)"
                          icon
                          v-if="!isExpanded"
                          ><v-icon>mdi-chevron-down</v-icon></v-btn
                        >
                        <v-btn
                          class="no-print"
                          @click="expand(false)"
                          icon
                          v-if="isExpanded"
                          ><v-icon>mdi-chevron-up</v-icon></v-btn
                        >
                      </td>
                      <td class="text-left text-capitalize">
                        {{ item.name }}
                      </td>
                      <td class="text-right">
                        {{ item.totalExpenses | currency }}
                      </td>
                    </tr>
                  </template>
                  <template
                    v-slot:body.append="{ headers }"
                    v-if="Expense.length > 0"
                  >
                    <tr
                      :style="{ 'background-color': $style.listTotal.filter }"
                    >
                      <td></td>
                      <td
                        :colspan="headers.length - 3"
                        class="text-right font-weight-bold"
                      >
                        Total
                      </td>
                      <td class="text-right font-weight-bold">
                        {{ total | currency }}
                      </td>
                    </tr>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="pa-0">
                      <v-card color="#F3F8FC" flat>
                        <v-card-text class="title">
                          <ExpenseList :PayeeId="item.id"></ExpenseList>
                        </v-card-text>
                      </v-card>
                    </td>
                  </template>
                </v-data-table>
              </v-card-text>
            </tbody>
          </table>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import ReportViewBar from "@/components/ReportViewBar";
import ExpenseList from "@/modules/ReportView/components/subComponents/Expense";
import payeeService from "@/modules/Expense/Payee/service";

export default {
  data() {
    return {
      start: null,
      end: null,
      categoryName: null,
      isLoading: true,
      Expense: [],
      expanded: [],
      headers: [
        { text: "", value: "data-table-expand" },
        {
          text: "NAME",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "TOTAL",
          align: "right",
          value: "totalExpenses",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    total() {
      let total = 0;
      this.Expense.map((row) => {
        total += row.totalExpenses;
      });
      return total;
    },
  },
  async created() {
    this.start = this.$route.query.start;
    this.end = this.$route.query.end;
    await this.getPayeeExpenses();
  },
  components: { ReportViewBar, ExpenseList },
  methods: {
    getPayeeExpenses() {
      return payeeService
        .getAllPayeeExpenseTotal({ start: this.start, end: this.end })
        .then((response) => {
          // console.log("expenses response", response);
          this.$Progress.finish();
          this.Expense = response.data;
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
  .no-print {
    display: none;
  }
  .detailBox {
    overflow: visible;
    height: 100%;
  }
  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}
.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}
.center {
  background-color: white;
  margin: auto;
  width: 100%;
}
/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
